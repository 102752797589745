import React from "react";

const WorkItems = ({ item }) => {
  return (
    <a
      href={item.link}
      className="work_button"
      target="_blank"
      rel="noreferrer"
    >
      <div className="work_card" key={item.id}>
        <img src={item.image} alt="" className="work_img" />
        <h3 className="work_title">{item.title}</h3>
        Demo <i className="bx bx-right-arrow-alt work_button-icon"></i>
      </div>
    </a>
  );
};

export default WorkItems;
