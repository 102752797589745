import React, { useState, useEffect } from "react";
import "./header.scss";

const Header = () => {
  const [Toggle, showMenu] = useState(false);
  const [activNav, setActiveNav] = useState("#home");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 50;
        const sectionBottom = sectionTop + section.clientHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setActiveNav(`#${section.id}`);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means the effect runs once after the initial render

  return (
    <header className="header">
      <nav className="nav container">
        <a
          href="#home"
          className="nav_logo"
          onClick={() => setActiveNav("#home")}
        >
          |HB
        </a>
        <div className={Toggle ? "nav_menu show_menu" : "nav_menu"}>
          <ul className="nav_list grid_menu">
            <li className="nav_item  un">
              <a
                href="#home"
                onClick={() => setActiveNav("#home")}
                className={
                  activNav === "#home"
                    ? "nav_link active_link menu_hover"
                    : "nav_link menu_hover"
                }
              >
                <i className="uil uil-estate nav_icon"></i> Home
              </a>
            </li>
            <li className="nav_item un">
              <a
                onClick={() => setActiveNav("#about")}
                className={
                  activNav === "#about"
                    ? "nav_link active_link menu_hover"
                    : "nav_link menu_hover "
                }
                href="#about"
              >
                <i className="uil uil-user nav_icon "></i> About
              </a>
            </li>
            <li className="nav_item">
              <a
                onClick={() => setActiveNav("#skills")}
                className={
                  activNav === "#skills"
                    ? "nav_link active_link menu_hover"
                    : "nav_link menu_hover"
                }
                href="#skills"
              >
                <i className="uil uil-briefcase-alt nav_icon"></i> Skills
              </a>
            </li>
            <li className="nav_item">
              <a
                onClick={() => setActiveNav("#portfolio")}
                className={
                  activNav === "#portfolio"
                    ? "nav_link active_link menu_hover"
                    : "nav_link menu_hover"
                }
                href="#portfolio"
              >
                <i className="uil uil-scenery nav_icon"></i> Portfolio
              </a>
            </li>
            <li className="nav_item">
              <a
                onClick={() => setActiveNav("#contact")}
                className={
                  activNav === "#contact"
                    ? "nav_link active_link menu_hover"
                    : "nav_link menu_hover"
                }
                href="#contact"
              >
                <i className="uil uil-message nav_icon"></i> Contact
              </a>
            </li>
          </ul>
          <i
            className="uil uil-times nav_close"
            onClick={() => showMenu(!Toggle)}
          ></i>
        </div>

        <div className="nav_toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
