import Alpakas from "../../assets/img/alpakas.jpg";
import Pepite from "../../assets/img/pepite.jpg";

export const projectsData = [
  {
    id: 1,
    image: Alpakas,
    title: "Alpakas",
    category: "app",
    link: "https://drive.google.com/drive/folders/1_HWJNkPNBUV0TYZP_8ytD6In5f7POQjQ?usp=sharing",
  },
  {
    id: 2,
    image: Pepite,
    title: "La pépite",
    category: "web",
    link: "https://lapepiterestaurant.netlify.app/",
  },
];

export const projectsNav = [{ name: "all" }, { name: "web" }, { name: "app" }];
