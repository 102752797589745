import React from "react";
import CountUp from "react-countup";

const Info = () => {
  return (
    <div className="about_info grid">
      <div className="about_box">
        <i class="bx bx-award about_icon" />
        <h3 className="about_title">Experience</h3>
        <CountUp enableScrollSpy scrollSpyDelay={1} end={5} duration={6} />
        <span className="about_subtitle"> years </span>
      </div>

      <div className="about_box">
        <i class="bx bx-briefcase-alt about_icon" />
        <h3 className="about_title">Completed</h3>
        <CountUp enableScrollSpy end={4} scrollSpyDelay={1} duration={6} />
        <span className="about_subtitle"> + Projects</span>
      </div>

      <div className="about_box">
        <i class="bx bx-heart about_icon"></i>
        <h3 className="about_title">Preference</h3>
        <span className="about_subtitle">Frontend</span>
      </div>
    </div>
  );
};

export default Info;
