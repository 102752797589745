import React, { useState } from "react";
import "./qualification.scss";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <section className="section  qualification">
      <h2 className="section_title">Qualification</h2>
      <span className="section_subtitle">My personal journey</span>
      <div className="qualification_container container">
        <div className="qualification_tabs">
          <div
            onClick={() => toggleTab(1)}
            className={
              toggleState === 1
                ? "qualification_button button_flex qualification_active"
                : "qualification_button button_flex"
            }
          >
            <i className="uil uil-graduation-cap qualification_icon"></i>
            Education
          </div>
          <div
            onClick={() => toggleTab(2)}
            className={
              toggleState === 2
                ? "qualification_button button_flex qualification_active"
                : "qualification_button button_flex"
            }
          >
            <i className="uil uil-briefcase-alt qualification_icon"></i>
            Experience
          </div>
        </div>
        <div className="qualification_sections">
          <div
            className={
              toggleState === 1
                ? "qualification_content qualification_content-active"
                : "qualification_content"
            }
          >
            <div className="qualification_data">
              <div className="qualification_content_margin">
                <h3 className="qualification_title">Le Wagon Berlin</h3>
                <span className="qualification_subtitle">
                  Fullstack Web Development Bootcamp
                </span>
                <div className="qualification_calendar">
                  <i className="uil uil-calendar-alt"></i>
                  {` 01/2021 - 03/2021`}
                </div>
              </div>

              <div>
                <span className="qualification_rounder"></span>
                <span className="qualification_line"></span>
              </div>
            </div>

            <div className="qualification_data">
              <div></div>

              <div>
                <span className="qualification_rounder"></span>
                <span className="qualification_line"></span>
              </div>

              <div>
                <h3 className="qualification_title">
                  Master Degree - ITEEM (Centrale Lille)
                </h3>
                <span className="qualification_subtitle">
                  Option : Industrial and entrepreneurial engineering
                </span>
                <div className="qualification_calendar">
                  <i className="uil uil-calendar-alt"></i>
                  {` 09/2013 - 06/2019`}
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification_content qualification_content-active"
                : "qualification_content"
            }
          >
            <div className="qualification_data">
              <div className="qualification_content_margin">
                <h3 className="qualification_title">
                  Junior Fullstack Developer
                </h3>
                <span className="qualification_subtitle">
                  Alpakas GmbH | Berlin
                </span>
                <div className="qualification_calendar">
                  <i className="uil uil-calendar-alt"></i>
                  {` 02/2022 - 06/2023`}
                </div>
              </div>

              <div>
                <span className="qualification_rounder"></span>
                <span className="qualification_line"></span>
              </div>
            </div>

            <div className="qualification_data">
              <div></div>

              <div>
                <span className="qualification_rounder"></span>
                <span className="qualification_line"></span>
              </div>

              <div>
                <h3 className="qualification_title">Teacher Assistant</h3>
                <span className="qualification_subtitle">
                  Le Wagon Bootcamp | Berlin
                </span>
                <div className="qualification_calendar">
                  <i className="uil uil-calendar-alt"></i>
                  {` 04/2021 - 06/2021`}
                </div>
              </div>
            </div>

            <div className="qualification_data">
              <div className="qualification_content_margin">
                <h3 className="qualification_title">Consultant Manager</h3>
                <span className="qualification_subtitle">
                  Right People Group | Berlin
                </span>
                <div className="qualification_calendar">
                  <i className="uil uil-calendar-alt"></i>
                  {` 04/2019 - 09/2020`}
                </div>
              </div>

              <div>
                <span className="qualification_rounder"></span>
                <span className="qualification_line"></span>
              </div>
            </div>

            <div className="qualification_data">
              <div></div>

              <div>
                <span className="qualification_rounder"></span>
                <span className="qualification_line"></span>
              </div>

              <div>
                <h3 className="qualification_title">
                  Business Development Manager
                </h3>
                <span className="qualification_subtitle">
                  LionsHome | Berlin
                </span>
                <div className="qualification_calendar">
                  <i className="uil uil-calendar-alt"></i>
                  {` 05/2017 - 01/2018`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
