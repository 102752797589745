import React from "react";

const Backend = () => {
  return (
    <div className="skills_content">
      <h3 className="skills_title">Backend</h3>
      <div className="skills_box">
        <div className="skills_group">
          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">Ruby on Rails</h3>
              <span className="skills_level">Beginner</span>
            </div>
          </div>
          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">Active Record</h3>
              <span className="skills_level">Beginner</span>
            </div>
          </div>
          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">SQL</h3>
              <span className="skills_level">Intermediate</span>
            </div>
          </div>
        </div>
        <div className="skills_group">
          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">Graphql</h3>
              <span className="skills_level">Intermediate</span>
            </div>
          </div>
          <div className="skills_data">
            <i class="bx bx-badge-check"></i>

            <div>
              <h3 className="skills_name">NodeJS</h3>
              <span className="skills_level">Beginner</span>
            </div>
          </div>
          <div style={{ color: "white" }} className="skills_data">
            <i style={{ color: "white" }} class="bx bx-badge-check"></i>

            <div>
              <h3 style={{ color: "white" }} className="skills_name">
                -
              </h3>
              <span className="skills_level">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;
